import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@aib/uikit-react/dist/Animations/Modal/Modal';
import * as SS from './MyMortgageAccessModel.styled';
import GenericPopupView from '@aib/uikit-react/dist/Content/GenericPopupView/GenericPopupView';
import { connect } from 'react-redux';
import { getHasViewedAccessMessage } from '../../../store/persistentState/persistentState.selectors';
import { hideDialog } from '../../../store/dialog/dialog.actions';
import Accordion from '../../Layout/Accordion/Accordion';
import { toggleAccordion } from '../../../store/openBanking/openBanking.actions';
import { getAccordionState } from '../../../store/openBanking/openBanking.selectors';

function MyMortgageAccessModal({
  isModalOpen,
  hideDialog,
  accordionState,
  toggleAccordion,
}) {
  return (
    <Modal open={isModalOpen} canCloseWithEscapeKey={true} onClick={hideDialog}>
      <GenericPopupView
        title="MyMortgage Access"
        onClose={hideDialog}
        id="my-mortgage-access-modal"
      >
        <SS.Wrapper>
          <SS.ConnectIcon />
          <SS.StepContainer>
            <SS.TopParagraph>
              Each applicant can add documents for everyone, but must register
              or use their own login to do so. They&apos;ll need the mortgage
              reference number. It&apos;s on the next screen.
            </SS.TopParagraph>
          </SS.StepContainer>
          <SS.AccordionGroup>
            <SS.AccordionTitle>
              How can other applicants get access?
            </SS.AccordionTitle>
            <Accordion
              title={'If registered for our mobile banking app'}
              open={accordionState.mobile}
              onClick={() => toggleAccordion('mobile')}
              id={'access-modal-mobile'}
              isTitleLight={true}
            >
              <SS.AccordionParagraph>
                1. Go to &apos;Mortgage&apos; under &apos;Products&apos;{' '}
              </SS.AccordionParagraph>
              <SS.AccordionParagraph>
                2. Select &apos;Connect my application&apos;
              </SS.AccordionParagraph>
              <SS.AccordionParagraph>
                3. Enter the mortgage reference number.
              </SS.AccordionParagraph>
            </Accordion>
            <Accordion
              title={'If not registered for our banking app'}
              open={accordionState.desktop}
              onClick={() => toggleAccordion('desktop')}
              id={'access-modal-desktop'}
              isTitleLight={true}
            >
              <SS.AccordionParagraph>
                1. Open &apos;mymortgage.aib.ie&apos;
              </SS.AccordionParagraph>
              <SS.AccordionParagraph>
                2. Select &apos;Get started&apos;
              </SS.AccordionParagraph>
              <SS.AccordionParagraph>
                3. Click &apos;Register now&apos;
              </SS.AccordionParagraph>
              <SS.AccordionParagraph>
                4. Select &apos;Connect my application&apos;
              </SS.AccordionParagraph>
              <SS.AccordionParagraph>
                5. Enter the mortgage reference number.
              </SS.AccordionParagraph>
            </Accordion>
          </SS.AccordionGroup>
          <SS.GotItButton
            onClick={hideDialog}
            isEnabled={true}
            buttonStyle={'secondary'}
            label={'Got it'}
          ></SS.GotItButton>
        </SS.Wrapper>
      </GenericPopupView>
    </Modal>
  );
}

MyMortgageAccessModal.propTypes = {
  hideDialog: PropTypes.func,
  isModalOpen: PropTypes.bool,
  accordionState: PropTypes.object,
  toggleAccordion: PropTypes.func,
};

const mapStateToProps = (state) => ({
  accordionState: getAccordionState(state),
  isModalOpen: !getHasViewedAccessMessage(state),
});

const mapDispatchToProps = (dispatch) => ({
  toggleAccordion: (accordionId) => {
    dispatch(toggleAccordion(accordionId));
  },
  hideDialog: () => {
    dispatch(hideDialog());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyMortgageAccessModal);
