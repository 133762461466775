function isEmpty(str) {
  return `${str}`.replace(/\s/g, '').length === 0;
}

export function lookupWithFallback(key, map, mapName) {
  if (isEmpty(key) && import.meta.env.DEV) {
    console.log('Utils.MapReader: Provided Key is Empty');
  }
  if (map.size < 1) {
    throw new Error('Utils.MapReader: No map defined');
  }
  if (!map.has(key) && import.meta.env.DEV) {
    console.log(`Utils.MapReader: ${key} not found in ${mapName}`);
  }
  const val = map.get(key);
  return val || key;
}
