import { clone, isNil } from 'ramda';
import stateTemplates from './maps/stateTemplates';
import MapReader from './mapReader';
import { lookupWithFallback } from './mapReaderFunctional';
import { isString, isEmpty, isArray } from './utils/typeChecks';
import { moduleName, debug } from './utils/config';
import langCommon from '~/shared/lang.common.json';
import settings from './maps/settings';

const mapReaderCache = {};

function lookupMapWithSubstitutions(map, key, subs = [], mapName) {
  const value = lookupWithFallback(key, map, mapName);
  return subs.length > 0 ? substituteStr(value, subs) : value;
}

const getMessageCode = (key, subs = []) =>
  lookupMapWithSubstitutions(
    new Map(Object.entries(langCommon)),
    key,
    subs,
    'lang-common'
  );

/**
 * @Public
 * Lookup the settings map for a corresponding value
 * failing gracefully if no value exists
 *
 * @param key {String} - The KEY to lookup
 * @param fallback {Any} - what to return if no setting exists
 */
const getSetting = (key, fallback = undefined) => {
  if (isNil(key)) {
    return false;
  }
  const value = lookupWithFallback(key, settings, 'settings');
  if (value !== key) {
    return value;
  }
  return !isNil(fallback) ? fallback : false;
};

/**
 * @private - lookup a property in map
 *
 * @param key {String} - they key in Map
 * @param map {Map} - The Map to lookup
 *
 * @returns {Map|Number|String} - or whatever is stored in provided map
 *
 */
const lookUpMap = (key, mapId, map) => {
  const fname = 'lookUpMap';
  // check if we have a map and reader already
  if (Object.keys(mapReaderCache).indexOf(mapId) === -1) {
    mapReaderCache[mapId] = new MapReader(mapId, map);
  }
  try {
    return mapReaderCache[mapId].get(key);
  } catch (error) {
    if (debug) {
      console.warn(`${moduleName}.${fname}: ${error.message}`);
    }
    return key;
  }
};

/**
 * Utility to Template our state objects to our reducers
 * handy to initialise our state in each reducer
 *
 * @param key {String} - corresponds to the key on the state reducer
 * @returns {Object} - An object corresponding to the initial state
 *
 */
const getStateTemplate = (key) => {
  const template = lookUpMap(key, 'state-keys', stateTemplates);
  return clone(template);
};

/**
 * @Public
 * Returns a capitalised string if passed a string
 * otherwise returns whatever was passed
 */

const capitalize = (s) => {
  return isString(s)
    ? `${s.substring(0, 1).toUpperCase()}${s.substring(1).toLowerCase()}`
    : s;
};

const isDebug = () => debug;

/**
 * @public
 * Takes a Unix timestamp as a string and returns a date object
 *
 * @param {string} dateAsString - a UNIX timestamp as a string
 *
 * @return {Date} - Human readable date
 */
const parseUnixDateString = (dateAsString, multiplier = 1000) => {
  const date = new Date(parseInt(dateAsString, 10) * multiplier);
  return date;
};

const getIsToday = (date) => {
  const today = new Date();
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return true;
  } else {
    return false;
  }
};

const convertTimeSegmentToTwoDigits = (timeSegment) =>
  ('0' + timeSegment).slice(-2);

/**
 * @public
 * Takes a Unix timestamp as a string and returns a human readable
 * date string
 *
 * @param {string} date - a UNIX timestamp as a number
 *
 * @return {string} - Human readable date
 */
const formatUnixDate = (unixTimeStamp, multiplier) => {
  const inputDate = parseUnixDateString(unixTimeStamp, multiplier);
  const inputDateString = inputDate.toString();
  const inputDateFormatString = 'DDD MMM DD YYYY HH:MM:SS';
  let outputDateFormatString = 'DD MMM YYYY';
  let outputDateFormatSeperator = ' ';
  const isToday = getIsToday(inputDate);
  // if today's date them display only the time
  if (isToday) {
    outputDateFormatString = 'HH:MM';
    outputDateFormatSeperator = ':';
  }
  // split the input date into its individual components
  const inputDateStringSplit = inputDateString.split(/[/ .:]/);
  // split the input date format into its individual components
  const inputDateFormatStringSplit = inputDateFormatString.split(/[/ .:]/);
  // create an assoc array to tokenize the input date
  const inputDateTokens = [];
  inputDateFormatStringSplit.forEach((value, i) => {
    inputDateTokens[value] = inputDateStringSplit[i];
  });
  // split the output date format into its individual components
  const outputDateFormatStringSplit = outputDateFormatString.split(/[/ .:]/);
  // construct output date format from input date tokens
  let outputDate = '';
  outputDateFormatStringSplit.forEach((value, i) => {
    outputDate += inputDateTokens[value];
    if (i < outputDateFormatStringSplit.length - 1)
      outputDate += outputDateFormatSeperator;
  });
  return outputDate;
};

/**
 * @public
 * Takes a ISO date as a string and returns a human readable date string
 */
const formatISODate = (isoDate) => {
  const inputDate = new Date(isoDate);
  const shortMonthNameFn = new Intl.DateTimeFormat('en-US', { month: 'short' })
    .format;
  const days = inputDate.getDate();
  const month = shortMonthNameFn(inputDate);
  const year = inputDate.getFullYear();
  const hours = convertTimeSegmentToTwoDigits(inputDate.getHours());
  const minutes = convertTimeSegmentToTwoDigits(inputDate.getMinutes());
  const isToday = getIsToday(inputDate);
  return isToday ? `${hours}:${minutes}` : `${days} ${month} ${year}`;
};

/**
 * @public
 *
 * @param {Array} names
 *
 * @returns {string}
 */
const getFormattedApplicantNames = (names) => {
  const useFamiliarNames = getSetting('USE_APPLICANT_FIRSTNAMES');

  return useFamiliarNames
    ? substituteStr(
        langCommon.INTRO_GREETING_MSG_FAMILIAR,
        names.length > 1 ? [names.join(' & ')] : names
      )
    : langCommon.INTRO_GREETING_MSG_IMPERSONAL;
};

/**
 * Return a string with the %N args substituted
 * with passed array of substutions
 *
 * @param str {String} - The string with '%N' placeholders
 * @param subs {Array} - An array of strings to replace placeholders
 */
const substituteStr = (s, subs = []) => {
  // be flexible with passed args
  const substitutions = isArray(subs) ? subs : [subs];
  return substitutions.reduce(
    (prev, next, index) => `${prev}`.replace(`%${index + 1}`, next),
    s
  );
};

const prepareMarkupToDangerouslySetInnerHTML = (text) => {
  return { __html: text };
};

const isTrue = /^true$/i;
const isStringTrue = (str) => isTrue.test(str);

// Public API
export default {
  getMessageCode,
  getStateTemplate,
  isEmpty,
  getFormattedApplicantNames,
  isDebug,
  convertTimeSegmentToTwoDigits,
  formatUnixDate,
  formatISODate,
  parseUnixDateString,
  getSetting,
  capitalize,
  substituteStr,
  prepareMarkupToDangerouslySetInnerHTML,
  isStringTrue,
};
