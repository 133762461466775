import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideDialog } from '../../../store/dialog/dialog.actions';
import AlertPopup from '@aib/uikit-react/dist/Content/AlertPopup/AlertPopup';
import dialogConfigFactory from './dialogConfigFactory';
import langCommon from '~/shared/lang.common.json';

const lang = {
  FILESIZE_BIG: 'Your file is too big.',
  ERROR_TRY_SMALL_FILE: 'Make sure your file is under 10MB and try again.',
};

function DocUploadFilesTooBigDialog({ oversizeFiles, hideDialog }) {
  const [visible, setVisible] = useState(false);
  const strFileList = oversizeFiles.map((file) => file.name).join('\n');
  const content = lang.ERROR_TRY_SMALL_FILE + '\n\n' + strFileList;
  const dialogConfig = dialogConfigFactory({
    heading: lang.FILESIZE_BIG,
    content,
    accept: langCommon.OK,
  });

  useEffect(() => {
    let timeoutId;
    timeoutId = setTimeout(() => setVisible(true), 0);

    return () => {
      timeoutId = clearInterval(timeoutId);
    };
  });

  return (
    <AlertPopup
      id="DocumentUploadDialog"
      title={dialogConfig.heading}
      confirmLabel={dialogConfig.accept}
      cancelLabel=""
      isOpen={visible}
      onCancel={hideDialog}
      onConfirm={hideDialog}
    >
      {dialogConfig.content}
    </AlertPopup>
  );
}

DocUploadFilesTooBigDialog.propTypes = {
  hideDialog: PropTypes.func.isRequired,
  oversizeFiles: PropTypes.array.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  oversizeFiles: ownProps.oversizeFiles,
});

const mapDispatchToProps = {
  hideDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocUploadFilesTooBigDialog);
