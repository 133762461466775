import { connect } from 'react-redux';
import { refreshSessionAction } from '../../../store/session';
import KeepAlive from '../../../MyMortgage/Session/KeepAlive/KeepAlive';
import { hasActiveSession } from '../../../store/appState/appState.selectors';

export const mapStateToProps = (state) => ({
  hasActiveSession: hasActiveSession(state),
});

const mapDispatchToProps = {
  onKeepAlive: refreshSessionAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(KeepAlive);
