import * as types from './dialog.types';

export const showDialog = (dialogType, dialogProps) => ({
  type: types.SHOW_DIALOG,
  dialogType,
  dialogProps,
});

export const hideDialog = () => ({
  type: types.HIDE_DIALOG,
});
