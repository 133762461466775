import React, { Component, lazy, Suspense } from 'react';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { isPrd } from './lib/envUtil';
import LandscapeOverlay from './MyMortgage/Animations/LandscapeOverlay/LandscapeOverlay';
import asyncComponent from './MyMortgage/Async/AsyncComponent';
import SplashPage from './MyMortgage/Login/SplashPage/SplashPage';
import ConfirmMobile from './MyMortgage/Login/ConfirmMobile/ConfirmMobile';
import ConnectApplicationConnected from './MyMortgage/Login/ConnectApplication/ConnectApplicationConnected';
import { createGlobalStyle } from 'styled-components';
import * as S from './App.styled';
import packageJson from '../package.json';
import { StyleSheetManager } from './lib/StylesheetManager';
import {
  MCX_CALCULATOR,
  SWITCHER_CALC,
  APPOINTMENT_BOOKING,
  CONFIRM_MOBILE,
  CONNECT_APPLICATION,
  LOG_IN_REGISTER,
  DIGITAL_MORTGAGE_PROTECTED,
  DIGITAL_MORTGAGE_UNPROTECTED,
  MANAGE_PROFILE_LANDING,
  TASKS,
  DOCUMENTS,
  MESSAGES,
  ROOT,
  SUBMISSION_IN_PROGRESS,
  DASHBOARD,
  VERIFY_SESSION,
  INACTIVITY_LANDING,
  LOGOUT_ON_MISMATCH,
  LOGOUT_LANDING,
} from './lib/urls';
import { getRedirectToUrl } from '~/shared/utils/functions/urlUtils';
import { AppContext } from './shared/hooks/AppContext';
import SessionWrapper from './MyMortgage/Session/SessionWrapper/SessionWrapper';
import LogoutLandingPage, {
  TYPES,
} from './MyMortgage/Login/LogoutLandingPage/LogoutLandingPage.jsx';
import LogoutOnMismatch from './MyMortgage/Login/LogoutOnMismatch/LogoutOnMismatch';
import utils from './lib/utils.js';
import {
  setGrecaptchaSiteKey,
  getGrecaptchaSiteKey,
} from './store/captchaMiddleware';

console.info(packageJson.name, packageJson.version);

/**
 * Hide Medlallia Feedback button inside of My Mortgage.
 */
const HideMedalliaStyle = createGlobalStyle`
  #kampyleButtonContainer {
    display: none;
  }

  #formLightboxContainer {
    display: none;
  }
`;

const AsyncAppAfterLogin = asyncComponent(
  () => import(/* webpackChunkName: 'after-login' */ './AppAfterLogin')
);

const Calculator = lazy(() => import('./Calculator'));
const SwitcherCalculator = lazy(
  () => import('~/MyMortgage/Calculator/SwitcherWrapper')
);

const AsyncAppointmentBookingPage = asyncComponent(
  () =>
    import(
      /* webpackChunkName: 'switcher' */ './MyMortgage/AppointmentBooking/AppointmentBookingWrapper'
    )
);

const DigitalMortgage = lazy(() => import('./DigitalMortgage'));

const queryParams = new URLSearchParams(location.search);
const mcxCalcId = queryParams.get('mcxCalcId');
const isVersionVisible = !isPrd();
const savedStateId = queryParams.get('savedStateId');
const existingCustomer =
  queryParams.get('existingCustomer')?.toLocaleLowerCase() === 'true';
const isMobileAppQuery = queryParams.get('isMobileApp');
const isMobileApp = isMobileAppQuery
  ? utils.isStringTrue(isMobileAppQuery)
  : utils.isStringTrue(sessionStorage.getItem('isMobileApp'));
sessionStorage.setItem('isMobileApp', isMobileApp.toString());

setGrecaptchaSiteKey(
  true,
  isPrd() ? packageJson.recaptchaKey.prod : packageJson.recaptchaKey.test
);

class App extends Component {
  render() {
    return (
      <AppContext.Provider value={{ isMobileApp }}>
        <StyleSheetManager>
          <HideMedalliaStyle />
          <S.GlobalAppStyles />
          <S.GlobalFontFamilyStyles />
          <LandscapeOverlay />
          <BrowserRouter>
            <Switch>
              <Route path={VERIFY_SESSION} exact />
              <Route path={LOG_IN_REGISTER} exact component={SplashPage} />
              <Route path={ROOT} exact component={SplashPage} />
              <Route path={CONFIRM_MOBILE} exact component={ConfirmMobile} />
              <Route path={INACTIVITY_LANDING} exact>
                <LogoutLandingPage type={TYPES.INACTIVITY} />
              </Route>
              <Route path={LOGOUT_LANDING} exact>
                <LogoutLandingPage />
              </Route>
              <Route
                path={LOGOUT_ON_MISMATCH}
                exact
                component={LogoutOnMismatch}
              ></Route>
              <Route
                path={CONNECT_APPLICATION}
                exact
                component={ConnectApplicationConnected}
              />
              <Route path={MANAGE_PROFILE_LANDING} exact>
                {() => {
                  const redirectTo = getRedirectToUrl();
                  console.info(`redirect to ${redirectTo}`);
                  location.href = redirectTo;
                  return <></>;
                }}
              </Route>

              <Route
                path={[
                  DIGITAL_MORTGAGE_PROTECTED,
                  DIGITAL_MORTGAGE_UNPROTECTED,
                ]}
                render={(props) => (
                  <SessionWrapper>
                    {(hasActiveSession, profileData, logout) => (
                      <Suspense fallback={null}>
                        <DigitalMortgage
                          {...props}
                          mcxCalcId={mcxCalcId}
                          isMobileApp={isMobileApp}
                          isVersionVisible={isVersionVisible}
                          savedStateId={savedStateId}
                          activeSession={hasActiveSession}
                          logout={logout}
                          profileData={profileData}
                          isExistingCustomer={existingCustomer}
                        />
                      </Suspense>
                    )}
                  </SessionWrapper>
                )}
              />

              <Route
                path={MCX_CALCULATOR}
                render={(props) => (
                  <SessionWrapper>
                    {(hasActiveSession, profileData, logout) => (
                      <Suspense fallback={null}>
                        <Calculator
                          {...props}
                          isVersionVisible={isVersionVisible}
                          activeSession={hasActiveSession}
                          logout={logout}
                          profileData={profileData}
                          isMobileApp={isMobileApp}
                        />
                      </Suspense>
                    )}
                  </SessionWrapper>
                )}
              />

              <Route
                path={SWITCHER_CALC}
                render={(props) => (
                  <SessionWrapper>
                    {(hasActiveSession, profileData, logout) => (
                      <Suspense fallback={null}>
                        <SwitcherCalculator
                          {...props}
                          eventListener={(a) => console.log(a)}
                          isVersionVisible={isVersionVisible}
                          activeSession={hasActiveSession}
                          logout={logout}
                          profileData={profileData}
                          isMobileApp={isMobileApp}
                        />
                      </Suspense>
                    )}
                  </SessionWrapper>
                )}
              />

              <Route
                path={`${APPOINTMENT_BOOKING}-new`}
                render={(props) => (
                  <AsyncAppointmentBookingPage
                    {...props}
                    isNewBooking={true}
                    isVersionVisible={isVersionVisible}
                    isGrecaptchaEnabled={true}
                    grecaptchaSiteKey={getGrecaptchaSiteKey()}
                  />
                )}
              />
              <Route
                path={`${APPOINTMENT_BOOKING}-change`}
                render={(props) => (
                  <AsyncAppointmentBookingPage
                    {...props}
                    isNewBooking={false}
                    isVersionVisible={isVersionVisible}
                    isGrecaptchaEnabled={true}
                    grecaptchaSiteKey={getGrecaptchaSiteKey()}
                  />
                )}
              />

              {/* After login, we get redirected to /contextRoot/tasks or /contextRoot/submission-in-progress */}
              {/* The react app doesn't yet support contextRoots so we strip it out again */}
              <Redirect from={`${ROOT}:contextRoot/tasks`} to={TASKS} />
              <Redirect
                from={`${ROOT}:contextRoot/submission-in-progress`}
                to={SUBMISSION_IN_PROGRESS}
              />
              <Route
                path={SUBMISSION_IN_PROGRESS}
                render={() => (
                  <Suspense fallback={null}>
                    <DigitalMortgage
                      isMobileApp={isMobileApp}
                      isVersionVisible={isVersionVisible}
                    />
                  </Suspense>
                )}
              />
              <Route path={TASKS} component={AsyncAppAfterLogin} />
              <Route path={DOCUMENTS} component={AsyncAppAfterLogin} />
              <Route path={MESSAGES} component={AsyncAppAfterLogin} />
              <Route path={DASHBOARD} exact component={AsyncAppAfterLogin} />
              <Redirect from="/" to={ROOT} />
            </Switch>
          </BrowserRouter>
        </StyleSheetManager>
      </AppContext.Provider>
    );
  }
}

export default App;
