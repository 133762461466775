import { setRedirectToUrl } from '~/shared/utils/functions/urlUtils';
import { authSitApiHost } from './envUtil';

export const ROOT = '/mortgages/';

export const DIGITAL_MORTGAGE_PROTECTED = ROOT + 'apply';
export const DIGITAL_MORTGAGE_UNPROTECTED = ROOT + 'intro';
export const MCX_CALCULATOR = ROOT + 'calculator';
export const SWITCHER_CALC = ROOT + 'switcher';
export const APPOINTMENT_BOOKING = ROOT + 'appointment';

export const SUBMISSION_IN_PROGRESS = ROOT + 'submission-in-progress';

export const LOG_IN_REGISTER = ROOT + 'log-in-or-register';
export const CONFIRM_MOBILE = ROOT + 'confirm-mobile';
export const CONNECT_APPLICATION = ROOT + 'connect-my-app';
export const MANAGE_PROFILE_LANDING = ROOT + 'manage-profile-landing';
export const DASHBOARD = ROOT + 'dashboard';
export const INACTIVITY_LANDING = ROOT + 'inactivity-landing';
export const LOGOUT_LANDING = ROOT + 'logout-landing';
export const LOGOUT_ON_MISMATCH = ROOT + 'logout-on-mismatch';

export const TASKS = ROOT + 'tasks';
export const DOCUMENTS = ROOT + 'documents';
export const MESSAGES = ROOT + 'messages';

export const VERIFY_SESSION = ROOT + 'verify-session';

export const navigateToProfilePage = (returnUrl = location.href) => {
  setRedirectToUrl(returnUrl);
  location.href = `${authSitApiHost()}/pf/idprofile.ping?LocalIdentityProfileID=aibuseridentityprofile1&returnTo=${returnUrl}`;
};

export const isDM = () =>
  location.pathname?.startsWith(DIGITAL_MORTGAGE_PROTECTED) ||
  location.pathname?.startsWith(DIGITAL_MORTGAGE_UNPROTECTED);
