import { buffers, eventChannel, END } from 'redux-saga';
import CookieUtils from '../../lib/cookieUtils';

const DONE = 4;

function createDocUploadChannel(endpoint, file) {
  return eventChannel(function (emitter) {
    const csrfHeaders = CookieUtils.csrfHeaders();
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    const onProgress = function (e) {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };

    const onFailure = function (responsePayload) {
      emitter({ err: new Error('Upload failed'), responsePayload });
      emitter(END);
    };

    xhr.upload.addEventListener('progress', onProgress);
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);

    xhr.onreadystatechange = function () {
      const { readyState, status } = xhr;

      if (readyState === DONE) {
        const { responseText } = xhr;
        const responseJson = safeJsonParse(responseText);

        if (status === 200 || status === 201) {
          emitter({ success: true });
          emitter(END);
        } else {
          onFailure(responseJson);
        }
      }
    };

    xhr.open('POST', endpoint, true);
    xhr.setRequestHeader(csrfHeaders.name, csrfHeaders.token);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.send(file);
    return function () {
      xhr.upload.removeEventListener('progress', onProgress);
      xhr.upload.removeEventListener('error', onFailure);
      xhr.upload.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}

function safeJsonParse(jsonString) {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return {};
  }
}

export default createDocUploadChannel;
