import { path } from 'ramda';
import migrationV7 from './migrations/persistentState.migration.7.0.0.js';
import migrationV9 from './migrations/persistentState.migration.9.0.0.js';
/**
 * Simplified implementation of the Database migrations pattern used by:
 * https://flywaydb.org/
 */

const allMigrations = {
  '7.0.0': migrationV7,
  '9.0.0': migrationV9,
};

function migrate(state = {}) {
  const schemaVersion = path(['__schemaVersion'], state) || '0.0.0';

  let newState = state;
  for (const migrationVersion in allMigrations) {
    const currentMigration = allMigrations[migrationVersion];
    if (schemaVersion < migrationVersion) {
      newState = currentMigration(newState);
    }
  }
  return newState;
}

export default migrate;
