import React from 'react';
import { StyleSheetManager as SM } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';

function shouldForwardProp(propName, target) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

export function StyleSheetManager({ children }) {
  return <SM shouldForwardProp={shouldForwardProp}>{children}</SM>;
}
