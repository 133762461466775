import styled, { css, keyframes } from 'styled-components';
import { breakpoint } from '@aib/design-tokens';
import clouds1Img from '/mortgages/assets/clouds-1.svg';
import clouds2Img from '/mortgages/assets/clouds-2.svg';

const slide = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-3500px, 0, 0);
  }
`;

const Clouds = css`
  height: 400px;
  width: 3500px;
  position: absolute;
  display: none;

  @media (min-width: ${breakpoint.medium}) {
    display: block;
  }
`;

export const Clouds1a = styled.div`
  background: url(${clouds1Img}) repeat-x;
  top: 15%;
  left: 0;
  animation: ${slide} 80s linear infinite;
  ${Clouds}
`;

export const Clouds1b = styled.div`
  background: url(${clouds1Img}) repeat-x;
  top: 15%;
  left: 3500px;
  animation: ${slide} 80s linear infinite;
  ${Clouds}
`;

export const Clouds2a = styled.div`
  background: url(${clouds2Img}) repeat-x;
  top: -5%;
  left: 0;
  animation: ${slide} 40s linear infinite;
  ${Clouds}
`;

export const Clouds2b = styled.div`
  background: url(${clouds2Img}) repeat-x;
  top: -5%;
  left: 3500px;
  animation: ${slide} 40s linear infinite;
  ${Clouds}
`;
