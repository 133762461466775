const REDIRECT_TO = 'mcx-redirect-to-url';

export const setRedirectToUrl = (
  redirectTo = window.location.pathname.toString()
) => {
  sessionStorage.setItem(REDIRECT_TO, redirectTo);
};

export const getRedirectToUrl = () => {
  const redirectTo = sessionStorage.getItem(REDIRECT_TO);
  sessionStorage.removeItem(REDIRECT_TO);
  return redirectTo || '/';
};
