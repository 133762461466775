import styled from 'styled-components';
import { color, fontSize, fontFamily } from '@aib/design-tokens';

const animationTime = '200ms';

export const AccordionGroup = styled.div`
  margin-top: 1rem;
  border-bottom: 1px solid ${color.grey04};
`;

export const AccordionBody = styled.div`
  font-size: ${fontSize.small};
  padding: 0 1rem;
  overflow: ${(props) => (props.open ? 'visible' : 'auto')};
  max-height: 100vh;
  transition: margin ${animationTime};
  margin: 0;
  line-height: 16px;
`;

export const AccordionHeader = styled.div`
  padding: 1rem;
  font-family: ${(props) =>
    props.isTitleLight ? 'aspira-medium, sans-serif' : fontFamily.bold};
  color: ${color.corePurple100};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: ${fontSize.small};
`;

export const AccordionWrapper = styled.div`
  background-color: ${color.baseWhite};
  border-top: 1px solid ${color.grey04};
  transition: background-color ${animationTime};

  ${AccordionBody} {
    height: 0;
  }

  * {
    content-visibility: visible;
  }

  ${AccordionHeader} svg {
    transition: transform ${animationTime};
  }

  &[open] {
    background-color: ${color.darkGreen10}80;

    ${AccordionHeader} svg {
      transform: rotate(180deg);
    }

    ${AccordionBody} {
      margin: 0;
      height: auto;
      padding-bottom: 1.5em;
    }
  }
`;
