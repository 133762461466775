import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import * as S from './Accordion.styled';
import IconChevronDown from '@aib/uikit-react/dist/Icons/IconChevronDown';

const Accordion = forwardRef(
  ({ title, children, open, onClick = () => {}, id, isTitleLight }, ref) => {
    return (
      <S.AccordionWrapper
        className="accordion-wrapper"
        open={open}
        ref={ref}
        id={`${id}-accordion`}
      >
        <S.AccordionHeader
          className="accordion-header"
          onClick={onClick}
          id={`${id}-accordion-header`}
          isTitleLight={isTitleLight}
        >
          {title}
          <IconChevronDown />
        </S.AccordionHeader>
        <S.AccordionBody
          className="accordion-body"
          open={open}
          id={`${id}-accordion-body`}
        >
          {children}
        </S.AccordionBody>
      </S.AccordionWrapper>
    );
  }
);

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  open: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string.isRequired,
  isTitleLight: PropTypes.bool,
};

export default Accordion;
