import { path } from 'ramda';

const PENDING_VERIFICATION = 10000;

export default function (oldStateStructure) {
  const out = {
    __schemaVersion: '7.0.0',
    hasViewedWelcomeMessage: false,
    hasViewedInfoMessage: true,
    conditionIdsWithDocsUploaded: {},
  };

  const taskViewReducer = path(['taskViewReducer'], oldStateStructure);
  if (taskViewReducer) {
    out.hasViewedWelcomeMessage = true;

    const sipTasks = Object.values(path(['2', 'tasks'], taskViewReducer) || {});
    const looTasks = Object.values(path(['4', 'tasks'], taskViewReducer) || {});

    [...sipTasks, ...looTasks].forEach((task) => {
      if (task) {
        const { conditionReferenceId, conditionStatusId } = task;
        if (
          conditionStatusId === PENDING_VERIFICATION &&
          Number.isInteger(conditionReferenceId)
        ) {
          out.conditionIdsWithDocsUploaded[conditionReferenceId] = true;
        }
      }
    });
  }
  return out;
}
