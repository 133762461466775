import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as S from './ContinueButton.styled';

function ContinueButton({
  onClick,
  label = 'Continue',
  continueButtonId,
  ...props
}) {
  const [isEnabled, setIsEnabled] = useState(props.isEnabled);

  useEffect(() => {
    setIsEnabled(props.isEnabled);
  }, [props.isEnabled]);

  const handleClick = useCallback(() => {
    setIsEnabled(false);
    onClick();
  }, [onClick]);

  return (
    <S.ButtonContainer>
      <S.ButtonStyled
        {...props}
        label={label}
        testId={props.testId || 'continueButton'}
        fullWidth
        disabled={!isEnabled}
        onClick={handleClick}
        isLoading={props.loading}
        id={continueButtonId}
        name="Continue to next step"
        type="submit"
      />
    </S.ButtonContainer>
  );
}

ContinueButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool,
  isStepValid: PropTypes.bool,
  label: PropTypes.string,
  testId: PropTypes.string,
  loading: PropTypes.bool,
  continueButtonId: PropTypes.string,
};

export default ContinueButton;
