import styled, { css } from '@aib/uikit-react/dist/styled-components.js';
import { color, breakpoint, fontFamily, fontSize } from '@aib/design-tokens';
import ContinueButton from '../../components/ContinueButton/ContinueButton';
import HeroRaw from './HeroImg';

export const layoutPadding = css`
  box-sizing: border-box;
  margin: 0 auto;
  padding: 1rem;

  @media all and (min-width: 0) and (max-width: 480px) and (orientation: landscape) {
    padding: 1.5rem;
  }

  @media (max-width: 839px) and (min-width: 480px) and (orientation: landscape) {
    padding: 1.5rem;
  }

  @media (max-width: 839px) and (min-width: 480px) {
    padding: 3rem;
  }

  @media (min-width: 840px) {
    padding: 3.75rem;
  }
`;

export const Hero = styled(HeroRaw)`
  margin-bottom: 0.5rem;
`;

export const Page = styled.div`
  height: 100%;

  @media (min-width: 576px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 420px;
    height: 680px;
    margin: auto;
  }

  @media (min-width: ${breakpoint.xLarge}) {
    width: 700px;
    border: solid 1px ${color.grey02};
    box-shadow:
      0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 0 0 0 rgba(0, 0, 0, 0.12),
      0 0 1px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const PageBody = styled.div`
  ${layoutPadding};

  &&& {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 2rem;
  }

  @media (min-width: ${breakpoint.medium}) {
    background: ${color.baseWhite};
    position: relative;
  }

  @media (min-width: 576px) {
    border: 1px solid ${color.grey02};
    padding: 3rem;
    background: ${color.baseWhite};
    height: 100%;
    box-sizing: border-box;
  }
`;

export const PageHead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PageTitle = styled.h1`
  margin-top: 1.6rem;
  margin-bottom: 0.2rem;
  color: ${color.corePurple100};
  font-size: 24px;
  font-family: 'aspira-medium', sans-serif;
  text-align: center;
`;

export const ContinueButtonStyled = styled(ContinueButton)`
  max-width: 360px;
  width: 100%;
  align-self: center;
`;

export const LogInRegisterPageFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6rem;

  @media (max-width: 375px) {
    gap: 1.5rem;
  }
`;

export const PageTopFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const BodyParagraph = styled.div`
  color: ${color.grey07};
  font-family: ${fontFamily.regular};
  font-size: ${fontSize.medium};
  max-width: 314px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const BodySubParagraph = styled.div`
  text-align: center;
  line-height: 22px;
  color: ${color.grey07};
  font-family: ${fontFamily.regular};
  font-size: ${fontSize.medium};
`;
