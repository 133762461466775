import React from 'react';
import PropTypes from 'prop-types';
import * as S from './LogInOrRegister.styled';

const LogInOrRegister = ({ handleClick }) => (
  <S.Page>
    <S.PageBody>
      <S.LogInRegisterPageFlex>
        <S.PageTopFlex>
          <S.PageHead>
            <S.Hero />
            <S.PageTitle>Log in or Register</S.PageTitle>
          </S.PageHead>
          <S.BodyParagraph>
            <S.BodySubParagraph>
              If you are registered for our AIB Phone & Internet Banking
              service, you can use your Registration number and Personal Access
              Code (PAC) to <b>log in</b> to MyMortgage.
            </S.BodySubParagraph>
            <S.BodySubParagraph>
              If not, please <b>register</b>.
            </S.BodySubParagraph>
          </S.BodyParagraph>
        </S.PageTopFlex>
        <S.ContinueButtonStyled
          onClick={handleClick}
          label="Log in or Register"
          testId="continueButton"
          isEnabled={true}
          continueButtonId={'login-register-continue'}
        />
      </S.LogInRegisterPageFlex>
    </S.PageBody>
  </S.Page>
);

LogInOrRegister.propTypes = {
  handleClick: PropTypes.func,
};

export default LogInOrRegister;
