import { createContext, useContext } from 'react';

interface profileData {
  firstName: string;
  customerType: string;
}

interface Context {
  isAibMobileApp: boolean;
  activeSession?: boolean;
  isExistingCustomer?: boolean;
  profileData?: profileData;
}

const defaultValue = { isAibMobileApp: true };

export const AppContext = createContext<Context>(defaultValue);
export const useAppContext = () => useContext(AppContext);
