import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import * as S from '~/shared/Generic.styles';
import { InputWrapper, LabelContainer } from './ConnectApplication.styled';
import FormControl from '@aib/uikit-react/dist/Form/FormControl/FormControl';
import Input from '@aib/uikit-react/dist/Form/Input/Input';
import InputLabel from '@aib/uikit-react/dist/Form/InputLabel/InputLabel';
import IconInfo from '@aib/uikit-react/dist/Icons/IconInfo';
import IconButton from '@aib/uikit-react/dist/Form/IconButton/IconButton';
import { color } from '@aib/design-tokens';
import Tooltip from '@aib/uikit-react/dist/Content/Tooltip/Tooltip';
import TooltipContainer from '@aib/uikit-react/dist/Content/Tooltip/TooltipContainer';
import { useAppContext } from '~/shared/hooks/AppContext';
import heroImg from './illustration-connect-1.svg';
import cookieUtils from '../../../lib/cookieUtils';
import CiamError from '../../Communications/CiamError/CiamError';
import AbsentPartyWarning from '../../Communications/AbsentPartyWarning/AbsentPartyWarning';
import { hideLoadingSpinner } from '../../../lib/spinnerUtils';

const lang = {
  HEADING_TITLE: 'Connect my application',
  BUTTONS_LOGIN: 'Login',
  BUTTONS_CONTINUE: 'Continue',
  SESSION_EXPIRED: 'Session Expired',
  SESSION_EXPIRED_DESCRIPTION:
    'You have been logged out due to inactivity. Please login again.',
};

const ConnectApplication = ({
  connectApplication,
  hasConnectFailed,
  hasAbsentParty,
  closeErrorModal,
}) => {
  const [appRef, setAppRef] = useState('');
  const submittedRef = useRef('');
  const { isMobileApp } = useAppContext();

  const handleChange = (val) => {
    let validated = val.target.value.replace(/[^0-9]/g, '');
    while (validated.charAt(0) === '0') {
      validated = validated.substring(1);
    }
    setAppRef(validated);
  };

  const handleContinue = () => {
    submittedRef.current = appRef;
    connectApplication(appRef, isMobileApp);
  };

  useEffect(() => {
    hideLoadingSpinner();

    const targetType = import.meta.env.REACT_APP_PROXY_TARGET_TYPE;
    if (targetType === 'JSON_SERVER') {
      cookieUtils.createMockCsrfCookies();
    }
  }, []);

  return (
    <S.PageMain>
      <S.Page>
        <S.PageBody>
          <S.PageHead>
            <S.PageHero src={heroImg} />
            <S.PageTitle>{lang.HEADING_TITLE}</S.PageTitle>

            <InputWrapper hasValue={appRef !== ''}>
              <FormControl fullWidth={true}>
                <LabelContainer>
                  <InputLabel>
                    <span>Mortgage reference number</span>{' '}
                  </InputLabel>

                  <TooltipContainer>
                    {(isVisible, showTooltip, hideTooltip) => (
                      <>
                        <IconButton onClick={showTooltip}>
                          <IconInfo size="small" color={color.darkGreen100} />
                        </IconButton>

                        <Tooltip
                          isVisible={isVisible}
                          onClickClose={hideTooltip}
                          title="If you don't have this number you can get it from your Mortgage Advisor or the other applicant."
                        ></Tooltip>
                      </>
                    )}
                  </TooltipContainer>
                </LabelContainer>

                <Input
                  fullWidth={true}
                  value={appRef}
                  onChange={handleChange}
                />
              </FormControl>
            </InputWrapper>
          </S.PageHead>
          <S.PageCtas>
            <S.ContinueButtonStyled
              id="connect_application_continue_btn"
              label={lang.BUTTONS_CONTINUE}
              buttonStyle="primary"
              fullWidth
              isEnabled={submittedRef.current !== appRef && appRef !== ''}
              onClick={handleContinue}
              testId="connectApplicationBtn"
            />
          </S.PageCtas>
        </S.PageBody>
      </S.Page>

      <AbsentPartyWarning
        showModal={hasAbsentParty}
        onClick={closeErrorModal}
      />
      <CiamError
        showModal={!hasAbsentParty && hasConnectFailed}
        onClick={closeErrorModal}
      />
    </S.PageMain>
  );
};

ConnectApplication.propTypes = {
  connectApplication: PropTypes.func.isRequired,
  hasConnectFailed: PropTypes.bool.isRequired,
  hasAbsentParty: PropTypes.bool.isRequired,
  closeErrorModal: PropTypes.func.isRequired,
};

export default ConnectApplication;
