import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import { map } from 'ramda';
import langCommon from '~/shared/lang.common.json';
import {
  fetchThreadsData,
  fetchThreadData,
  fetchPostSubmitThreadReply,
  fetchPostSubmitThreadData,
  fetchPutMarkThreadRead,
} from '../api';
import Utils from '../../lib/utils';
import errorCodes from '../../lib/maps/errorCodes';
import * as types from './messages.types';
import { markThreadRead, postThread } from './messages.actions';
import * as persistentStateTypes from '../persistentState/persistentState.types';
import {
  sortByKey,
  getCurrentThreadUnreadMessagesCount,
} from './messages.selectors';
import * as navigationSelectors from '../navigation/navigation.selectors';
import * as navigationActions from '../navigation/navigation.actions';
import { showSnackbarAction } from '../snackbar';

export function* getThreadsData(fetchThreadsDataFn) {
  try {
    const response = yield call(fetchThreadsDataFn);
    if (response.ok) {
      const data = response.resp;
      yield put({ type: types.FETCH_THREADS_SUCCESS, data });
    } else {
      yield put({ type: types.FETCH_THREADS_FAILURE, err: response.err });
    }
  } catch (e) {
    yield put({ type: types.FETCH_THREADS_FAILURE });
  }
}

export const mapMessageDataToMessage = (messageData) => {
  const {
    messageId: id,
    message: messageText,
    timeMASViewed,
    timeCustomerViewed,
    messageDateCreated,
    addedByCustomer,
  } = messageData;

  const getAddedByString = () => {
    if (addedByCustomer) {
      return langCommon.MESSAGE_CARD_THREAD_SOURCE_CUSTOMER;
    }
    return langCommon.MESSAGE_CARD_THREAD_SOURCE_AIB;
  };
  const addedByString = getAddedByString();

  const message = {
    id,
    messageText,
    timeMASViewed,
    timeCustomerViewed,
    dateCreated: Utils.formatUnixDate(messageDateCreated),
    addedBy: addedByString,
    addedByCustomer,
  };
  return message;
};

export function* getThreadData(fetchThreadDataFn, action) {
  try {
    yield put({ type: types.FETCH_THREAD_IN_PROGRESS });
    const response = yield call(fetchThreadDataFn, action.threadId);
    if (response.ok) {
      const threadData = response.resp;
      const data = {
        threadId: action.threadId,
        threadMessages: map(
          (messageData) => mapMessageDataToMessage(messageData),
          sortByKey(threadData, 'messageDateCreated')
        ),
      };

      yield put({ type: types.FETCH_THREAD_SUCCESS, data });

      const state = yield select(getState);
      const currentThreadUnread = getCurrentThreadUnreadMessagesCount(
        state.messagesReducer
      );

      if (currentThreadUnread > 0) {
        yield put(markThreadRead(action.threadId));
      }
    } else {
      yield put({ type: types.FETCH_THREAD_FAILURE, err: response.err });
    }
  } catch (e) {
    yield put({ type: types.FETCH_THREAD_FAILURE });
  }
}

export function* displayMessageSendingError(
  failureAction,
  actionText = undefined,
  action = undefined
) {
  yield all([
    yield put(failureAction),
    yield put(
      showSnackbarAction({
        timeout: 3750,
        message: 'Message not sent',
        actionText,
        action,
      })
    ),
  ]);
}

export function* submitThreadReply(submitThreadReplyFn, action) {
  const unsavedData = {
    id: 'unsaved-' + Math.random(),
    messageText: action.data.message,
    dateCreated: Utils.formatUnixDate(Date.now() / 1000),
    addedBy: langCommon.MESSAGE_CARD_THREAD_SOURCE_CUSTOMER,
    addedByCustomer: true,
  };

  yield put({ type: types.UNSAVED_THREAD_REPLY, unsavedData });
  yield put({
    type: types.UPDATE_THREAD_MODIFIED_DATE,
  });

  try {
    const response = yield call(
      submitThreadReplyFn,
      ...[action.data.threadId, action.data.message]
    );
    if (response.ok) {
      const { messageId: id, messageDateCreated } = response.resp;
      const data = {
        id,
        messageText: action.data.message,
        dateCreated: Utils.formatUnixDate(messageDateCreated),
        addedBy: langCommon.MESSAGE_CARD_THREAD_SOURCE_CUSTOMER,
        addedByCustomer: true,
        threadId: action.data.threadId,
      };
      yield put({
        type: types.SUBMIT_THREAD_REPLY_SUCCESS,
        data,
        unsavedId: unsavedData.id,
      });
      yield put({
        type: persistentStateTypes.DELETE_DRAFT_MESSAGE,
        data: {
          key: action.data.threadId,
        },
      });
    } else {
      yield put({
        type: types.SUBMIT_THREAD_REPLY_FAILURE,
        unsavedId: unsavedData.id,
        err: response.err,
      });
    }
  } catch (err) {
    yield put({
      type: types.SUBMIT_THREAD_REPLY_FAILURE,
      unsavedId: unsavedData.id,
      err,
    });
  }
}

export function* submitPostThread(submitThreadPostFn, action) {
  const { subject, description } = action.data;
  try {
    const apiResponse = yield call(
      submitThreadPostFn,
      ...[subject, description]
    );

    if (apiResponse.ok) {
      yield all([
        put({ type: types.POST_THREAD_SUCCESS }),
        put({ type: types.HIDE_CREATE_MESSAGE }),
        put(
          showSnackbarAction({
            message: 'Message sent',
          })
        ),
        put({
          type: persistentStateTypes.DELETE_DRAFT_MESSAGE,
          data: {
            key: 'newMessage',
          },
        }),
        put({ type: types.FETCH_THREADS }),
      ]);
    } else {
      yield call(
        displayMessageSendingError,
        { type: types.POST_THREAD_FAILURE, err: apiResponse.err },
        errorCodes.get('MESSAGE_REPLY_ERROR_ACTION'),
        postThread(subject, description)
      );
    }
  } catch (err) {
    yield displayMessageSendingError({ type: types.POST_THREAD_FAILURE, err });
  }
  /** Request/promise for storing the thread (first) message - {description} -
   * in :id-messages aka route
   * "/mortgages/:id/messagethreads/17133/messages": "/mortgages/:id/17133-messages"
   * on json-server
   * * */
}

export const getState = (state) => state;
export function* submitPutMarkThreadRead(submitThreadReadFn, action) {
  try {
    const apiResponse = yield call(submitThreadReadFn, action.data.threadId);
    if (apiResponse.ok) {
      yield put({ type: types.MARK_THREAD_READ_SUCCESS });
      yield put({ type: types.FETCH_THREADS });
      const state = yield select(getState);
      const currentThreadUnread = yield getCurrentThreadUnreadMessagesCount(
        state.messagesReducer
      );
      const messageNotifications = yield navigationSelectors.getNotifications(
        state.navigationReducer
      ).messagesNotifications;
      yield put(
        navigationActions.subReadMessagesFromNotifications(
          messageNotifications,
          currentThreadUnread
        )
      );
    } else {
      yield put({ type: types.MARK_THREAD_READ_FAILURE, err: apiResponse.err });
    }
  } catch (err) {
    yield put({ type: types.MARK_THREAD_READ_FAILURE, err });
  }
}

function* threadSaga() {
  yield takeEvery(types.FETCH_THREAD, getThreadData, fetchThreadData);
}

function* threadsSaga() {
  yield takeEvery(types.FETCH_THREADS, getThreadsData, fetchThreadsData);
}

function* submitReplySaga() {
  yield takeEvery(
    types.SUBMIT_THREAD_REPLY,
    submitThreadReply,
    fetchPostSubmitThreadReply
  );
}

function* postThreadSaga() {
  yield takeEvery(
    types.POST_THREAD,
    submitPostThread,
    fetchPostSubmitThreadData
  );
}

function* putMarkThreadReadSaga() {
  yield takeEvery(
    types.MARK_THREAD_READ,
    submitPutMarkThreadRead,
    fetchPutMarkThreadRead
  );
}

export {
  threadSaga,
  threadsSaga,
  submitReplySaga,
  postThreadSaga,
  putMarkThreadReadSaga,
};
