import { LOCATION_CHANGE } from 'connected-react-router';
import messagesEventsMap from './messagesEventDefinitions';
import docUploadEventsMap from './docUploadEventDefinitions';
import tasksEventsMap from './taskEventDefinitions';
import { mediaDevicesGoogleTag } from './eventDefinitions';
import debounceEvent from '@redux-beacon/debounce-event';

mediaDevicesGoogleTag();

const pageView = (action) => ({
  hitType: 'pageview',
  pagePath: action.payload.pathname,
  pageTitle: action.payload.pathname,
});

export default {
  ...messagesEventsMap,
  ...docUploadEventsMap,
  ...tasksEventsMap,
  [LOCATION_CHANGE]: debounceEvent(500, pageView),
};
