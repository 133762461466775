import { prop } from 'ramda';

const getAccordionState = ({ openBankingReducer }) =>
  prop('accordionState', openBankingReducer);

const getCurrentStep = ({ openBankingReducer }) => {
  return prop('step', openBankingReducer);
};

const getProviders = ({ openBankingReducer }) => {
  const groupedProviders = { IRL: [] };
  prop('providers', openBankingReducer).forEach((provider) => {
    provider.regions.forEach((region) => {
      if (groupedProviders[region]) groupedProviders[region].push(provider);
      else groupedProviders[region] = [provider];
    });
  });

  if (!groupedProviders.IRL.length) delete groupedProviders.IRL;
  return groupedProviders;
};

const getSelectedProvider = ({ openBankingReducer }) =>
  prop('provider', openBankingReducer);

const isModalOpen = ({ openBankingReducer }) => {
  return prop('showModal', openBankingReducer);
};

const isOBCallPending = ({ openBankingReducer }) => {
  return prop('isPending', openBankingReducer);
};

export {
  getAccordionState,
  getCurrentStep,
  isModalOpen,
  getProviders,
  getSelectedProvider,
  isOBCallPending,
};
