import { connect } from 'react-redux';
import { showDialog, hideDialog } from '../../../store/dialog/dialog.actions';
import AutoLogout from '../../../MyMortgage/Session/AutoLogout/AutoLogout';
import { hasActiveSession } from '../../../store/appState/appState.selectors';

export const mapStateToProps = (state) => ({
  hasActiveSession: hasActiveSession(state),
});
export const mapDispatchToProps = (dispatch) => ({
  showDialog: (onRefresh) => {
    // Before showing the auto logout dialog, hide any other dialogs that might be visible:
    dispatch(hideDialog());
    dispatch(showDialog('AUTO_LOGOUT_DIALOG', { onRefresh }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoLogout);
