import styled from '@aib/uikit-react/dist/styled-components.js';
import { spacing } from '@aib/design-tokens';
import Button from '@aib/uikit-react/dist/Form/Button/Button.js';

export const ButtonContainer = styled.div`
  margin-top: auto;
  width: 100%;
`;

export const ButtonStyled = styled(Button)`
  margin-bottom: ${spacing.small};
  margin-left: auto;
  margin-right: auto;
`;
